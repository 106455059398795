import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import React from "react";

// Customizable Area Start

interface ReportData {
  id: number;
  patent_report_pdf: string | null;
  publication_report_pdf: string | null;
  product_report_pdf: string | null;
  provisional_report_pdf: string | null;
  non_provisional_report_pdf: string | null;
  account_id: number;
  created_at: string;
  updated_at: string;
}
interface SupportAnalysts {
  id: number,
  customer_name: string,
  email: string,
  date_of_submission:string,
  prompt: string,
  message: string,
  created_at: string,
  updated_at: string,
  document_url: string
}
interface RecentSearchRes {
  data: RecentSearchRes[];
  id: string;
  attributes: {
    title: string;
    download_availability: boolean
    support_analysts: SupportAnalysts[]
    created_at: string;
    user_string: string
    case_id: string | null
  }
  account_id: number;
  updated_at: string;
  archived: null | string;
  admin_user_id: null | number | string;
  category: string;
  error: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuShow: null | HTMLElement;
  downloadShow: null | HTMLElement;
  downloadlinks:ReportData;
  openAnalystModel: boolean;
  loading: boolean;
  recentSearchData: RecentSearchRes[];
  searchData: RecentSearchRes[];
  recentSearchId: string | Blob
  recentMessage :  string
  analystDetails: {
    id: string;
    attributes: {
      title: string;
      created_at: string;
      user_string: string | null
    }
  }
  openRemoveList:boolean
  caseId: string | null
  title: string
  searchId: string
  currnetPage:number
  dataError: boolean
  email: string
  recentSearchError: string
  recentMessageError: string
  recentSearchLoaclStorageData:string
  analyistDownloadurl:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class UserProfilesRecentSearchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetRecentSearchID: string = "";
  apiDownloadReportAPIEndPoint:string="";
  apiRecentSearchMsgCallID: string = ""
  apiRemoveListCallID: string = ""
  apiGetSearchID: string = ""
  debounceTimeout: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      menuShow: null,
      downloadShow:null,
      downloadlinks: {
        id: 1,
        patent_report_pdf: "",
        publication_report_pdf: "",
        product_report_pdf: "",
        provisional_report_pdf: "",
        non_provisional_report_pdf: "",
        account_id: 1,
        created_at: "",
        updated_at: "",
      },     
      openAnalystModel: false,
      loading: false,
      recentSearchData: [],
      recentSearchId: "",
      recentMessage: "",
      analyistDownloadurl:"",
      analystDetails: {
        id: "",
        attributes: {
          title: "",
          created_at: "",
          user_string: null
        } ,
      },
      openRemoveList:false,
      caseId: null,
      title:"",
      searchId:"",
      currnetPage:1,
      dataError: false,
      searchData: [],
      email: "",
      recentSearchError: "",
      recentMessageError:"",
      recentSearchLoaclStorageData:""
      // Customizable Area End
    };
    // Customizable Area Start
    this.handleScroll = this.handleScroll.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = await getStorageData("token")
    if(!authToken){
      this.logInNavigation()
    }
    const email = await getStorageData("userProfile")
    const parseData = JSON.parse(email)
    this.setState({
      email:parseData.email
    })
    this.handleGetRecentSearchApi()
    window.addEventListener('scroll', this.handleScroll);
    const serachHistoryCaseId = await getStorageData("searchHistory")
    const serachHistoryCaseIdParse = JSON.parse(serachHistoryCaseId)
    if(serachHistoryCaseIdParse){
      this.setState({
        searchId: serachHistoryCaseIdParse
      }, () => {
        this.handleGetSearchApi();
      });
    }
    removeStorageData("searchHistory")
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiGetRecentSearchID) {
        if (responseJson?.data?.data?.length) {
          const filterdRes = this.state.recentSearchData?.filter((item: RecentSearchRes) => item.attributes.case_id !== this.state.caseId)
          this.setState({
            recentSearchData: [...responseJson?.data?.data ],
            loading: false,
          })
        } 
        else if(responseJson?.error === "No searches present"){
          this.setState({
            dataError: true,
            loading: false
          })
        }
        else {
          this.setState({
            loading: false
          })
        } 
        const tokenError = responseJson?.errors[0]?.token
        if(tokenError){
          this.logInNavigation()
        }

      }
       if (apiRequestCallId === this.apiRecentSearchMsgCallID) {
        this.setState({loading: false, openAnalystModel: false ,recentMessage : ""})
      } 

      this.downloadReportHandel(responseJson,apiRequestCallId);
      this.deleteReciverFunc(apiRequestCallId, responseJson);
      this.handleSearchReceive(responseJson,apiRequestCallId) 
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  
  checkpublicationreport()
  {
    return this.state.downloadlinks.publication_report_pdf === null ? true : false;
  }
  checkproductreport()
  {
    return this.state.downloadlinks.product_report_pdf === null ? true : false;
  }
  checkpatentreport()
  {
    return this.state.downloadlinks.patent_report_pdf === null ? true : false;
  }
  checknonprovisonalreport()
  {
    return this.state.downloadlinks.non_provisional_report_pdf === null ? true : false;
  }
  checkprovisonalreport()
  {
    return this.state.downloadlinks.provisional_report_pdf === null ? true : false;
  }
  deleteReciverFunc = (apiRequestCallId: string, responseJson: {
    errors: any; message: string; 
}) => {
    if (apiRequestCallId === this.apiRemoveListCallID) {

      if (!responseJson.errors) {     
          this.setState({ loading: false, openRemoveList: false, currnetPage: this.state.currnetPage,
            searchData:[]
           }, 
        );
        this.handleGetRecentSearchApi()
      }
  }
  };

  handleSearchReceive = (responseJson:RecentSearchRes,apiRequestCallId:string) => {
    if(apiRequestCallId === this.apiGetSearchID) {
        this.setState({
          searchData: responseJson?.data,
            loading: false,
            recentSearchError: responseJson?.error
        })
      }
  }
  
  logInNavigation = async () => {
    removeStorageData("token")
    const navigateMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    navigateMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    navigateMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    navigateMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(navigateMessage);
  };
  menuClickOpen = (event: React.MouseEvent<HTMLElement>, value:string,title: string) => {        
    this.setState({ menuShow: event.currentTarget,caseId:value,title:title })
  }
  downloadClickOpen = (event: React.MouseEvent<HTMLElement>, value:string,title: string,analyistUrl:string) => {            
    this.setState({ downloadShow: event?.currentTarget,caseId:value,title:title,analyistDownloadurl: analyistUrl})
  }
  handleMenuClose = () => {
    this.setState({ menuShow: null})
  }

  handleDownloadClose =()=>{
    this.setState({downloadShow:null,analyistDownloadurl:""})
  }

  hendleOpenAnalystModel = (recentRes:RecentSearchRes) => {
    this.setState({ openAnalystModel: true, recentSearchId: recentRes.id, analystDetails:recentRes })
  }

  handleAnalystModelClose = () => {
    this.setState({ openAnalystModel: false })
  }

  handleGetRecentSearchApi = async () => {    
    this.setState({
      loading: true
    })
    const headers = {
      token: await getStorageData("token")
    };

    const getProfileMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRecentSearchID = getProfileMessage.messageId;

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRecentSearchEndPoint}?per_page=${10}&page=${this.state.currnetPage ?? 1}`
    );

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getProfileMessage.id, getProfileMessage);
  };

  getFormatedDate = (date: string) => {
    const originalDate = moment(date);
    const targetDate = moment(originalDate).format("MMMM DD [at] h:mm A");
    return targetDate;
  }

  handleSubmit = () => {
    if(!this.state.recentMessage.trim()) {
      this.setState({
        recentMessageError: "Please enter a message."
      })
    } else {
      this.recentSearchMsgApi()
    }
  }

  recentSearchMsgApi = async () => {
  
    const header = {
      token: await getStorageData("token"),
    };

    this.setState({ loading: true });
    const formData = new FormData();

    formData.append("prompt_id", this.state.recentSearchId);
    formData.append("message", this.state.recentMessage);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRecentSearchMsgCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postRecentSearchMsgEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleChange = (event: { target: { value: string } }) => {
    this.setState({recentMessage: event.target.value, recentMessageError: ""})
  }

  handleDownloads = async (download: string | null) => {
    if (!download) {
      return; // Exit early if download is null
    }

    const downloadUrl = download;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleDownload = async (download: SupportAnalysts[]) => {
    console.log(download , "11111111111download");
    
    const downloadUrl = download[0].document_url;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleRemoveList = () => {
    this.setState({
      openRemoveList: true,
      menuShow: null
    })
  }

  handleRemoveListClose = () => {
    this.setState({
      openRemoveList: false
    })
  }


  removeListApi = async () => {
  
    const header = {
      token: await getStorageData("token"),
    };

    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRemoveListCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteRemoveListENdPoint}?id=${this.state.caseId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigatetoLandingPage = () => {
    setStorageData("advanchData",this.state.title)
    removeStorageData("searchId")
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPageWeb"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  } 

  

  handleSearch = (event: { target: { value: string } }) => {
    clearTimeout(this.debounceTimeout);

    const value = event.target.value;
    if(!value){
      this.setState({
        searchData: [],
        recentSearchData: [...this.state.recentSearchData],
        recentSearchError:""
      })
      this.handleGetRecentSearchApi()
    }else {
      this.debounceTimeout = setTimeout(() => {
        this.setState({
          searchId: value
        }, () => {
          this.handleGetSearchApi();
        });
      }, 1000);
    }

  };

  handleGetSearchApi = async () => {
    this.setState({
      loading: true
    })
    const headers = {
      token: await getStorageData("token")
    };

    const getProfileMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSearchID = getProfileMessage.messageId;

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRecentSearchEndPoint}?case_id=${this.state.searchId}`
    );

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getProfileMessage.id, getProfileMessage);
  };

    handleScroll() {
      const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollTop + windowHeight >= documentHeight && !this.state.loading && !this.state.dataError) {
      this.setState({currnetPage:this.state.currnetPage + 1})
      this.handleGetRecentSearchApi();
    }
  }
  

  handelDownloadReport= async(case_id:any)=>{

    this.setState({
      loading: true,
    })

    const headers = {
      token: await getStorageData("token")
    };
    const getProfileMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDownloadReportAPIEndPoint = getProfileMessage.messageId;

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_landingpage2/provisional_specifications/pdf_reports?case_id=${case_id}`
    );

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getProfileMessage.id, getProfileMessage);

  }


  downloadReportHandel = (responseJson:any,apiRequestCallId:string) => {
    console.log(responseJson,"  Hellllo")
    if(apiRequestCallId === this.apiDownloadReportAPIEndPoint) {
      if (responseJson.data) {
        this.setState({
          loading: false,
          downloadlinks: responseJson.data,
        })
      }
      else {
        const nullReportData: ReportData = {
          id: 0,
          patent_report_pdf: null,
          publication_report_pdf: null,
          product_report_pdf: null,
          provisional_report_pdf: null,
          non_provisional_report_pdf: null,
          account_id: 0,
          created_at: '',
          updated_at: ''
        };
        this.setState({ loading: false, downloadlinks: nullReportData })
      }
    }
  }

  // Customizable Area End
          }

import React from "react";
// Customizable Area Start
import { Box, Dialog, Grid, IconButton, LinearProgress, styled, Checkbox, Button, CircularProgress } from "@material-ui/core";
import Footer from "../../../components/src/footer.web";
import Header from "../../../components/src/Header.web";
import { closeIcon, keyExpend, document, overlapping, whiteCalender, downloadwhite, leftArrow, listView, genaretingDownload, blueCheckedIcon, uncheckedIcon } from "./assets";
import Chart from 'react-apexcharts';
import PublicationLandingPage from "./PublicationLandingPage.web";
import ProductLandingPage from "./ProductLandingPage.web";
import Loader from "./Loader.web";
import { Link } from "react-router-dom"
import NonProvisionalSpecification from './NonProvisionalSpecificationPage.web';
import ProvisionalSpecificationPage from "./ProvisionalSpecificationPage.web";
interface Item {
  publication_number: string;
  figures?: string[];
  images?: string[];
  publication_date: string;
  inventor: string;
  snippet: string;
  title: string;
}

// Customizable Area End

import KeyFeatureLandingPageController, {
  Props,
} from "./KeyFeatureLandingPageController";

export default class KeyFeatureLandingPage extends KeyFeatureLandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderNoResultsMessage = () => {
    return (
      <>
        <Box component="div" className="no-result-text">
          No results found. Please click the button below to regenerate the search.
        </Box>
        <Grid item xs={12} className="regenerate-btn">
          <button
            className="start-new-searchBtn"
            id="new-search-btn"
            onClick={this.regeneratePatentResultApi}
          >
            Generate Results Again
          </button>
        </Grid>
      </>
    );
  };

  renderPatentResults = (hasPatentResults: boolean) => {
    const { selectResult, loading, Haserrors } = this.state;
    if (selectResult === 'Patent' && !loading) {
      if (hasPatentResults) {
        return this.gridViewFunction();
      } else if (Haserrors==="Your account has run out of searches.") {
        return this.renderError()
      } else {
        return this.renderNoResultsMessage();
      }
    }
    return null;
  };

  renderNoPublicationResultsMessage = () => {
    return (
      <>
        <Box component="div" className="no-result-text">
          No results found. Please click the button below to regenerate the search.
        </Box>
        <Grid item xs={12} className="regenerate-btn">
          <button
            className="start-new-searchBtn"
            id="new-search-btn"
            onClick={this.regeneratePublicationResultApi}
          >
            Generate Results Again
          </button>
        </Grid>
      </>
    );
  };

  renderPublicationResults = (hasPublicationResults: boolean, navigation: any) => {
    const { selectResult, loading, publicationSerpData, girdView, Haserrors } = this.state;
    if (selectResult === 'Publication' && !loading) {
      if (hasPublicationResults) {
        return (
          <PublicationLandingPage
            publicationSerpData={publicationSerpData}
            gridView={girdView}
            navigation={navigation}
            id=""
            seTselectedPublicationNumbers={this.setSelectedPublicationNumbers}
            clearTrigger={this.state.clearTrigger}
          />
        );
      } else if (Haserrors==="Your account has run out of searches.") {
        return this.renderError()
      } else {
        return this.renderNoPublicationResultsMessage();
      }
    }
    return null;
  };

  renderNoProductResultsMessage = () => {
    return (
      <>
        <Box component="div" className="no-result-text">
          No results found. Please click the button below to regenerate the search.
        </Box>
        <Grid item xs={12} className="regenerate-btn">
          <button
            className="start-new-searchBtn"
            id="new-search-btn"
            onClick={this.regenerateProductResultApi}
          >
            Generate Results Again
          </button>
        </Grid>
      </>
    );
  };

  renderProductResults = (hasProductResults: boolean, navigation: any) => {
    const { selectResult, loading, productDetails, girdView, Haserrors } = this.state;
    if (selectResult === 'Products' && !loading) {
      if (hasProductResults) {
        return (
          <ProductLandingPage
            navigation={navigation}
            id=""
            gridView={girdView}
            productDetails={productDetails}
            seTselectedProductNumbers={this.seTselectedProductNumbers}
            clearTrigger={this.state.clearTrigger}
          />
        );
      } else if (Haserrors==="Your account has run out of searches.") {
        return this.renderError()
      }
      else {
        return this.renderNoProductResultsMessage();
      }
    }
    return null;
  };



  renderError = () => {
    return (
        <Box component="div" className="no-result-text">Your search limit has been reached. Please contact the Administrator for assistance.</Box>
    )
  }
  renderImages = (item: Item, gridView: boolean) => {
    return (
      <>
        {item.figures && (
          <Box className={gridView ? 'keyFeature-img-main-gridView' : 'keyFeature-img-main'}>
            {item.figures.map((figures, inde) => (
              <Box key={inde} className="img-loop">
                <img className="img-class" src={figures} alt={`figures-${inde}`} />
              </Box>
            ))}
          </Box>
        )}

        {item.images && (
          <Box className={gridView ? 'keyFeature-img-main-gridView' : 'keyFeature-img-main'}>
            {item.images.map((images, inde) => (
              <Box key={inde} className="img-loop">
                <img className="img-class" src={images} alt={`images-${inde}`} />
              </Box>
            ))}
          </Box>
        )}
      </>
    );
  };

  
  renderKeyFeatureMainText = (item:Item, gridView:boolean) => {
    const isChecked = this.state.selectedPatentNumbers.includes(item.publication_number);
    const onCheckboxChange = () => this.onChangepatentselect(item.publication_number);
    return gridView ? (
      <StyledKeyFeatureMainTextGridView>
        <Checkbox
          checked={isChecked}
          onChange={onCheckboxChange}
          data-test-id="changeCheckBoxDataTestId2"
          checkedIcon={<img src={blueCheckedIcon} alt="checked" />}
          icon={<img src={uncheckedIcon} alt="unchecked" />}
          color="primary"
          className="logIn-checkbox-label"
        />
        {item?.title}
      </StyledKeyFeatureMainTextGridView>
    ) : (
      <StyledKeyFeatureMainText>
        <Checkbox
          checked={isChecked}
          onChange={onCheckboxChange}
          id="changeCheckBoxDataTestId1"
          checkedIcon={<img src={blueCheckedIcon} alt="checked" />}
          icon={<img src={uncheckedIcon} alt="unchecked" />}
          color="primary"
          className="logIn-checkbox-label"
        />
        {item?.title}
      </StyledKeyFeatureMainText>
    );
  };
  

  ItemDetails(item: Item, gridView: boolean) {
    const isVisible = this.state.visibleImages.includes(item.publication_number);
    return (
      <Grid item xs={12}>
        <StyledMappingButtonsMainBox>
          <Box component={gridView ? StyledBoxGridView : StyledBox} id="list-grid">
            {item?.publication_number}
          </Box>
          <Box component={gridView ? StyledBoxGridView2 : StyledBox2} id="list-grid-publication_date">
            {item?.publication_date}
          </Box>
          <Box component={gridView ? StyledBoxGridView2 : StyledBox2} id="list-grid-inventor">
            {item?.inventor}
          </Box>
          { 
            item.figures && item.figures.length > 1 && 
          <Box component={gridView ? StyledBoxGridView1 : StyledBox2} id="list-grid-Images">
              <StyledShowImagesButtonimage id="list-grid-Images-button" onClick={() => this.toggleImageVisibility(item.publication_number)}>
              {isVisible ? "Hide Images" : "Show Images"}
              </StyledShowImagesButtonimage>
          </Box> }
        </StyledMappingButtonsMainBox>
        {this.state.visibleImages.includes(item.publication_number) ? (
                  this.renderImages(item, this.state.girdView)
                ) : (
                  <> </>
                )}

        <Box className={gridView ? "keyFeature-description-text-gridview" : "keyFeature-description-text"}>
          {item?.snippet}
        </Box>
      </Grid>
    );
  }

  gridViewFunction = () => {
    return (
      <Box className="keyFeature-gridView">
        <Grid container spacing={3}>
          {this.state.patentSerpData.map((item, index) => (
            <Grid item xs={this.state.girdView ? 6 : 12} key={index}>
              <Box className={this.state.girdView ? "keyFeature-white-container-gridView" : "keyFeature-white-container"}>
                {this.renderKeyFeatureMainText(item, this.state.girdView)}
                {this.ItemDetails(item, this.state.girdView)}
                <Box className="keyFeature-p-text">{item?.abstract}</Box>
                {this.state.visibleGraphs.includes(item.publication_number) ? (
                  <Box className={this.state.girdView ? "keyFeature-chart-box-gridView" : "keyFeature-chart-box"}>
                    <Box className={ this.getGridViewChartClassName()}>
                      <Chart
                        options={{
                          chart: {
                            height: 274,
                            type: 'line',
                            zoom: {
                              enabled: false
                            },
                            toolbar: {
                              show: false
                            }
                          },
                          xaxis: {
                            categories: [2005, 2006, 2007, 2008],
                            labels: { style: charts.xaxisChart },
                            title: { text: 'Years', style: charts.titleBody },
                          },
                          yaxis: {
                            title: { text: 'Applications', style: charts.yaxisChart },
                            labels: {
                              formatter: (value: { toLocaleString: () => string; }) => value.toLocaleString(),
                              style: charts.labelsCss,
                            },
                          },
                          markers: { size: 5, colors: ['#DC2626'] },
                          stroke: { curve: 'straight' },
                        }}
                        series={[
                          {
                            name: 'Applications',
                            color: '#DC2626',
                            data: [250000, 350000, 450000, 550000],
                          },
                        ]}
                        type="line"
                        height={274}
                        width="100%"
                      />
                    </Box>
                    <Box className={this.getgridViewClassName()}>
                    {
  this.state.formattedData.find(
    (formattedItem: any) => formattedItem.publication_number === item.publication_number
  ) && (
    <>
      <Box className="overlapping-head-Box">
        <Box className="overlapping-text">
          View Overlapping
        </Box>
        <Box className="percent-text">
          Percent
        </Box>
      </Box>
      <Box className="overlapping-border"></Box>
    </>
  )
}

{(() => {
  const formattedItem = this.state.formattedData.find(
    (entry: any) => entry.publication_number === item.publication_number
  );

  const errorOverlap = this.state.errorOverLappingData.find(
    (entry: any) => entry.publication_number === item.publication_number
  );

  if (formattedItem) {
    return formattedItem.mapping.map((mappingItem: any, mappingIndex: any) => (
      <Box key={`${mappingIndex}`} className="overlapping-box-container">
        <Box className="overlapping-Label-percentage">
          <Box className="overlapping-Label">{mappingItem.label}</Box>
          <Box className="overlapping-percentage">{mappingItem.value}%</Box>
        </Box>
        <LinearProgress
          variant="determinate"
          value={mappingItem.value}
          className="KeyFeature-progressStyle"
        />
      </Box>
    ));
  } else if (errorOverlap) {
    return (
      <Box className="overlapping-box-container">
        <Box className="overlapping-message">
          {errorOverlap.message}
        </Box>
      </Box>
    );
  }
  return null;
})()}



                    </Box>
                  </Box>
                 ) : (
                 null
                )}
                <Box className="buttons-main-box">
                  <Box className="buttons-box" onClick={() => this.toggleGraphVisibility(item.publication_number)}>
                  {this.state.circularLoader.includes(item.publication_number) ? 
                  
                  <CircularProgress value={10} size={20} /> :
                    <img
                      src={overlapping}
                      alt="overlapping" />      }    
                      {
                         this.state.visibleGraphs.includes(item.publication_number) ?       
                        <Box className="button-text">Hide Overlapping</Box> :
                        <Box className="button-text">View Overlapping</Box>
                      }
                  </Box>
                  {this.state.girdView &&
                    <Link className="link-text"
                      to={`/patentdetail/${item.publication_number}`}>
                      <Box id="descriptionPageGrid" className="buttons-box-red">
                        <img
                          src={whiteCalender}
                          alt="whiteCalender" />
                        <Box className="button-text-white"> View More </Box>
                      </Box>
                    </Link>
                  }
                  <Link className="link-text" to={`/SimilarDocuments/${item.publication_number}`}>
                    <Box className="buttons-box">
                      <img src={document} alt="document" />
                      <Box className="button-text">
                        Similar Document
                      </Box>
                    </Box>
                  </Link>
                  {!this.state.girdView &&
                    <Link
                      className="link-text"
                      to={`/patentdetail/${item.publication_number}`}
                    >
                      <Box
                        className="buttons-box-red"
                        id="descriptionPage"
                      >
                        <img
                          src={whiteCalender}
                          alt="whiteCalender"
                        />
                        <Box className="button-text-white">View More</Box>
                      </Box>
                    </Link>
                  }
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  };
  handleNavigation = () => {
    const { selectResult, loading, patentSerpData, publicationSerpData, productDetails, girdView } = this.state;
    const { navigation } = this.props;
    const hasPatentResults = patentSerpData?.length > 0 && this.state.patenterrors;
    const hasPublicationResults = publicationSerpData?.length > 0 && this.state.publicationerror;
    const hasProductResults = this.state.productError
    return (
      <Box>

        {this.renderPatentResults(hasPatentResults)}
        {this.renderPublicationResults(hasPublicationResults, navigation)}
        {this.renderProductResults(hasProductResults, navigation)}
        {
          selectResult === 'Provisional Specification' && !loading && (
            <ProvisionalSpecificationPage navigation={this.props.navigation} />
          )}
        {
          selectResult === 'Non-Provisional Specification' && !loading && (
            <NonProvisionalSpecification navigation={this.props.navigation} />
          )}
        {
          (selectResult === undefined || selectResult === "") && !loading && (
            <Box component="div" className="no-result-text">At least one of patents or publication must be true</Box>
          )}
      </Box>
    );
  }
  resultsize = (): number => {
    const { selectResult, loading, patentSerpData, publicationSerpData, productDetails } = this.state;
    const hasPatentResults = patentSerpData?.length > 0 && this.state.patenterrors;
    const hasPublicationResults = publicationSerpData?.length > 0 && this.state.publicationerror;
    if (loading) return 0;
    switch (selectResult) {
      case 'Patent':
        return hasPatentResults ? patentSerpData.length : 0;
      case 'Publication':
        return hasPublicationResults ? publicationSerpData.length : 0
      case 'Products':
        return productDetails?.length || 0;
      default:
        return 0;
    }
  };


  // Customizable Area End

  render() {    
    return (
      <>
        <Loader
          loading={this.state.loading}
        />
        <Header title={""} />
        <KeyFeatureLandingPageContainerCss>
          <Grid container>
            <Box
              className="back-arrowimain-box">
              <Box
                className="back-arrow-box">
                <img
                  src={leftArrow}
                  alt="leftArrow"
                  id="navigate-landingPage"
                  className="back-arrow-img"
                  onClick={() => this.goBackToLandingPage()}
                />
                <Box
                  className="back-arrow-text"
                  id="heading">View Key Features</Box>
              </Box>
            </Box>
          </Grid>
          <Box className="result-Main">
            <Box>
              <Box className="caseId-main"> Case ID : <Box component="span" className="box-span">{this.state.caseid}</Box>
              </Box>
              <Box className="top-ten-result">
                {(this.state.selectResult === 'Provisional Specification' || this.state.selectResult === 'Non-Provisional Specification') ? null :
                  `Showing top ${this.resultsize()} results`}
              </Box>
              <Box className="keyfeature-box">
                <Grid item xs={12}
                  className="keyFeature-search-grid"
                >
                  <Box
                    className="keyFeature-search-box-looking">
                    {this.state.selectedTabs.map((item: string, index: number) => (
                      <Box
                        className={item !== this.state.selectResult ? "keyFeature-box-search-loop" : "keyFeature-box-search-loop-select"}
                        id={`statusChange${index}`}
                        onClick={() => this.onClickSetStutus(item)}
                      >
                        <Box
                          key={index}
                          className="keyFeature-box-name">
                          {item}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>
                {
                  (this.state.selectResult === 'Provisional Specification' || this.state.selectResult === 'Non-Provisional Specification') ? null : (
                    <Box className="download-Report-main">
                      <Box
                        // className="report-buttons-box"
                        id="downloadOpen"
                        onClick={() => this.downloadDetails()}
                        className={`report-buttons-box ${(this.state.selectResult === "Patent" && this.state.selectedPatentNumbers.length > 0) ||
                            (this.state.selectResult === "Publication" && this.state.selectedPublicationNumbers.length > 0) ||
                            (this.state.selectResult === "Products" && this.state.selectedProductNumbers.length > 0)
                            ? "enabled"
                            : "disabled"
                          }`}
                      >
                        <img
                          src={downloadwhite}
                          alt="download"
                        />
                        <Box className="report-button-text">Download Report</Box>
                      </Box>
                      <Box className="keyFeature-img-box">
                        <img
                          className="listView-style"
                          src={this.state.girdView ? listView : keyExpend}
                          id="listView"
                          alt="keyExpend"
                          onClick={() => this.handleClickGridView()}
                        />
                      </Box>
                    </Box>
                  )
                }
                <ReportDialog
                  open={this.state.downReportModel}
                  fullWidth
                  aria-labelledby="customized-dialog-title"
                  id="downloadClose"
                  PaperProps={{
                    elevation: 0,
                    className: "download-modal",
                  }}
                  BackdropProps={{
                    className: "backdrop-modal",
                  }}
                >
                  <Box className="download-closeIconStyle">
                    <IconButton aria-label="close">
                      <img
                        src={closeIcon}
                        width={24}
                        alt="closeIcon"
                        id="download-Click-Close"
                        height={24}
                        onClick={() => this.downloadModelClose()}
                      />
                    </IconButton>
                  </Box>
                  <Box className="down-box">
                    <Box className="download-main-box">
                      <img
                        className="download-Icon-style"
                        src={genaretingDownload}
                        alt="genaretingDownload"
                      />
                      <Box>
                        <Box className="download-title-model"> We are generating your report,</Box>
                        <Box className="download-model-Text">Once completed you can Download it from your Profile Settings</Box>
                      </Box>
                    </Box>
                  </Box>
                </ReportDialog>
              </Box>
            </Box>
            {this.handleNavigation()}
          </Box>
        </KeyFeatureLandingPageContainerCss>
        <Footer />
      </>
    );
  }
}

// Customizable Area Start

const StyledMappingButtonsMainBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 0,
  flexWrap: "wrap",
}));

const StyledBoxGridView = styled(Box)(({ theme }) => ({
  display: "flex",
  fontFamily: "Inter",
  fontWeight: 700,
  width: "fit-content",
  borderRadius: 8,
  color: "#DF4833",
  margin: "7px 0",
  textTransform: "none",
  fontSize: 20,
  background: "#FCEDEB",
  alignItems: "center",
  gap: 8,
  border: 'none',
  padding: "0px 7px",
  height: 58,
}));


const StyledBoxGridView2 = styled(Box)(({ theme }) => ({
  display: "flex",
  fontFamily: "Inter",
  fontWeight: 400, // Change font weight to normal
  width: "fit-content",
  borderRadius: 8,
  margin: "7px 0",
  textTransform: "none",
  fontSize: 14,
  alignItems: "center",
  gap: 8,
  border: 'none',
  padding: "0px 7px",
  height: 58,
}));

const StyledBox2 = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  fontWeight: 400, // Change font weight to normal
  fontFamily: "Inter",
  fontSize: 14,
  height: 58,
  width: "fit-content",
  textTransform: "none",
  alignItems: "center",
  gap: 8,
  padding: "0px 7px",
  display: "flex",
  marginTop: 24,
  border: 'none',
}));

const StyledShowImagesButtonimage = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent", // Make the background transparent
  color: theme.palette.primary.main, // Use primary color for text
  fontFamily: "Inter",
  fontSize: "14px", 
  fontWeight: 400,
  padding: "0",
  textTransform: "none", // Prevent uppercasing the button text
  '&:hover': {
    backgroundColor: "transparent", // Make the hover background transparent
  },
}));

const StyledBox1 = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  fontWeight: 700,
  fontFamily: "Inter",
  fontSize: 14,
  height: 58,
  width: "fit-content",
  textTransform: "none",
  background: "#FCEDEB",
  alignItems: "center",
  gap: 8,
  padding: "0px 7px",
  display: "flex",
  marginTop: 24,
  border: 'none',
}));

const StyledBoxGridView1 = styled(Box)(({ theme }) => ({
  display: "flex",
  fontFamily: "Inter",
  fontWeight: 700,
  width: "fit-content",
  borderRadius: 8,
  color: "#DF4833",
  margin: "7px 0",
  textTransform: "none",
  fontSize: 20,
  alignItems: "center",
  gap: 8,
  border: 'none',
  padding: "0px 7px",
  height: 58,
}));


const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  color: "#DF4833",
  fontWeight: 700,
  fontFamily: "Inter",
  fontSize: 14,
  height: 58,
  width: "fit-content",
  textTransform: "none",
  background: "#FCEDEB",
  alignItems: "center",
  gap: 8,
  padding: "0px 7px",
  display: "flex",
  marginTop: 24,
  border: 'none',
}));

const StyledKeyFeatureMainText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Inter',
  marginBottom: 16,
  fontWeight: 700,
  fontSize: 16,
  gap: '10px', // Add space between checkbox and text
}));

const StyledKeyFeatureMainTextGridView = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
  fontFamily: 'Inter',
  marginTop: 24,
  paddingBottom: "4px",
  fontSize: 20,
  gap: '10px', // Add space between checkbox and text
}));

const ReportDialog = styled(Dialog)({
  "& .download-modal": {
    borderRadius: 0,
    background: '#F5F5F4',
    maxWidth: 738,
    height: 298,
  },
  "& .backdrop-modal": {
    backgroundColor: 'rgba(15, 23, 42, 0.4)'
  },
  "& .download-main-box": {
    alignItems: "center",
    gap: 30,
    flexDirection: "column",
    display: "flex",
    textAlign: "center",
  },
  "& .download-Icon-style": {
    cursor: 'pointer',
  },
  "& .download-title-model": {
    fontFamily: 'Inter',
    fontWeight: 700,
    color: '#4F5356',
    marginBottom: "15px",
    fontSize: 20,
  },
  "& .download-model-Text": {
    fontWeight: 700,
    fontFamily: 'Inter',
    color: '#4F5356',
    fontSize: 20,
  },
  "& .down-box": {
    padding: 60,
    height: "100%",
  },
  "& .download-closeIconStyle": {
    position: 'absolute',
    right: 10,
    top: 8,
  },
  "& .placeholder-graph": {
    width: "100%",
    height: "294px",
    backdropFilter: "blur(10px)",
    background: "rgba(245, 245, 244, 0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    position: "relative",
  },
  "& .placeholder-overlay": {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    color: "#0F172A",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    background: "rgba(255, 255, 255, 0.3)",
    borderRadius: "8px",
    cursor: "pointer",
  },
});

const charts = {
  xaxisChart: {
    colors: '#DF4833',
    fontSize: '12px',
  },
  titleBody: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#F0ABA1',
  },
  yaxisChart: {
    fontWeight: 'bold',
    color: '#F0ABA1',
    fontSize: '12px',
  },
  labelsCss: {
    colors: '#DF4833',
    fontSize: '12px',
  }
};

const KeyFeatureLandingPageContainerCss = styled(Box)({
  "& .back-arrowimain-box": {
    padding: "30px 0px 20px 108px",
    background: "#E8E7E7",
    "@media(max-width:600px)": {
      padding: "40px 20px",
    },
    "@media(max-width:1280px)": {
      padding: "40px 40px"
    },
    "@media(max-width:960px)": {
      padding: "40px 20px"
    },
  },
  "& .back-arrow-box": {
    display: "flex",
    gap: 16,
    alignItems: "center",
  },
  "& .back-arrow-img": {
    cursor: "pointer"
  },
  "& .back-arrow-text": {
    fontSize: "18px",
    fontFamily: "Inter",
    color: "#212324",
    fontWeight: 400,
  },
  "& .result-Main": {
    padding: "10px 108px 20px",
    background: "#E8E7E7",
    "@media(max-width:600px)": {
      padding: "0px 20px",
    },
    "@media(max-width:1280px)": {
      padding: "0px 40px"
    },
    "@media(max-width:960px)": {
      padding: "0px 20px"
    },
  },
  "& .keyFeature-white-container": {
    borderRadius: 8,
    padding: "32px 24px",
    background: "#F5F5F4",
  },
  "& .start-new-searchBtn": {
    marginTop: "24px",
    color: "#FFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    width: 250,
    borderRadius: 8,
    fontSize: 16,
    background: "#EF4444",
    gap: 8,
    textTransform: "none",
    height: 56,
    padding: "10px 16px",
    cursor: "pointer",
    border: 'none',
  },
  "& .regenerate-btn": {
    display: "flex",
    justifyContent: "center"
  },

  "& .no-result-text": {
    fontWeight: 700,
    fontSize: 24,
    fontFamily: "Inter",
    justifyContent: "center",
    height: "200px",
    display: "flex",
    alignItems: "center"
  },
  "& .keyFeature-white-container-gridView": {
    borderRadius: 8,
    background: "#F5F5F4",
    minHeight: "auto",
    padding: "32px 16px",
  },
  "& .keyFeature-main-text": {
    fontFamily: "Inter",
    marginBottom: 16,
    fontWeight: 700,
    fontSize: 16,
  },
  "& .keyFeature-main-text-gridView": {
    fontWeight: 700,
    fontFamily: "Inter",
    marginTop: 24,
    fontSize: 20,
  },
  "& .box-span": {
    color: "#DF4833"
  },
  "& .keyFeature-img-main": {
    padding: "24px",
    flexWrap: "wrap",
    gap: 24,
    height: 172,
    display: "flex",
    overflowY: "scroll",
    background: "#F0F0F0",
  },
  "& .keyFeature-img-main-gridView": {
    flexWrap: "wrap",
    gap: 24,
    display: "flex",
    height: 172,
    overflowY: "scroll",
    padding: "0 10px",
  },
  "& .img-loop": {
    height: 172,
    objectFit: "contain",
    width: 166,
  },
  "& .img-class": {
    width: 166,
    height: 172,
  },
  "& .keyFeature-Id-btn": {
    borderRadius: 8,
    color: "#DF4833",
    fontWeight: 700,
    fontFamily: "Inter",
    fontSize: 14,
    height: 58,
    width: "fit-content",
    textTransform: "none",
    background: "#FCEDEB",
    alignItems: "center",
    gap: 8,
    padding: "0px 24px",
    display: "flex",
    marginTop: 24,
    border: 'none',
  },
  "& .keyFeature-Id-btn-gridView": {
    display: "flex",
    fontFamily: "Inter",
    fontWeight: 700,
    width: "fit-content",
    borderRadius: 8,
    color: "#DF4833",
    margin: "24px 0",
    textTransform: "none",
    fontSize: 20,
    background: "#FCEDEB",
    alignItems: "center",
    gap: 8,
    border: 'none',
    padding: "0px 24px",
    height: 58,
  },
  "& .keyFeature-p-text": {
    marginTop: 10,
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: 16,
    marginBottom: 24,
    fontWeight: 400,
  },
  "& .keyFeature-box-search-loop": {
    background: "#F1F5F9",
    cursor: "pointer",
    height: 46,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    width: "fit-content",
    "@media(max-width:550px)": {
      width: "100%",
    },
  },
  "& .keyFeature-gridView": {
    marginTop: 30
  },
  "& .keyFeature-box-search-loop-select": {
    width: "fit-content",
    display: 'flex',
    cursor: "pointer",
    borderRadius: 4,
    alignItems: 'center',
    height: 46,
    background: "#FCEDEB",
    padding: '0 16px',
    "@media(max-width:550px)": {
      width: "100%",
    },
  },
  "& .keyFeature-search-box-looking": {
    gap: 10,
    display: "flex",
    "@media(max-width:550px)": {
      flexWrap: "wrap",
    },
  },
  "& .keyFeature-search-grid": {
    justifyContent: 'space-between',
    display: "flex",
  },
  "& .keyFeature-box-name": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    color: "#64748B",
  },
  "& .top-ten-result": {
    fontWeight: 400,
    fontFamily: "Inter",
    marginBottom: 10,
    fontSize: 14,
    paddingTop: 5,
    color: "#DF4833",
  },
  "& .caseId-main": {
    fontWeight: 700,
    marginBottom: 0,
    fontFamily: "Inter",
    color: "#4F5356",
    fontSize: 19,
  },
  "& .keyfeature-box": {
    display: 'flex',
    marginBottom: 30,
    "@media(max-width:830px)": {
      flexWrap: "wrap",
    },
  },
  "& .download-Report-main": {
    gap: 24,
    display: 'flex',
    alignItems: 'center',
    "@media(max-width:830px)": {
      marginTop: 30
    },
  },
  "& .keyFeature-img-box": {
    borderRadius: 8,
    background: "#F1F5F9",
    padding: 10,
  },
  "& .listView-style": {
    cursor: "pointer"
  },
  "& .keyFeature-chart-box": {
    gap: 32,
    display: 'flex',
    "@media(max-width:900px)": {
      flexDirection: "column",
    },
  },
  "& .keyFeature-chart-box-gridView": {
    gap: 10,
    display: 'flex',
  },
  "& .keyFeature-chart-main-box": {
    padding: "24px",
    height: "274px",
    background: "#fff",
    width: "35%",
    borderRadius: 8,
    "@media(max-width:900px)": {
      width: "94%"
    },
    "@media(max-width:700px)": {
      width: "92%"
    },
    "@media(max-width:500px)": {
      width: "89%"
    },
    "@media(max-width:400px)": {
      width: "85%"
    },
  },
  "& .keyFeature-chart-main-box-gridView": {
    padding: "10px",
    borderRadius: 8,
    width: "50%",
    background: "#fff",
    height: "274px",
  },
  "& .keyFeature-Overlapping-main-box": {
    padding: "24px",
    borderRadius: 8,
    height: "274px",
    width: "35%",
    background: "#fff",
    "@media(max-width:900px)": {
      width: "94%"
    },
    "@media(max-width:700px)": {
      width: "92%"
    },
    "@media(max-width:500px)": {
      width: "89%"
    },
    "@media(max-width:400px)": {
      width: "85%"
    },
  },
  "& .keyFeature-Overlapping-main-box-gridView": {
    borderRadius: 8,
    height: "274px",
    background: "#fff",
    width: "50%",
    padding: "10px",
  },
  "& .KeyFeature-progressStyle": {
    borderRadius: "10px",
    width: '70%',
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#DF4833"
    }
  },
  "& .overlapping-Label": {
    fontFamily: "Inter",
    marginBottom: "5px",
    color: "#0F172A",
    fontWeight: 400,
    fontSize: 14,
  },
  "& .overlapping-percentage": {
    color: "#0F172A",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
  },
  "& .overlapping-head-Box": {
    justifyContent: "space-between",
    display: 'flex',
  },
  "& .overlapping-border": {
    background: "#E2E8F0",
    height: "1px",
    width: "100%",
    margin: "20px 0",
  },
  "& .overlapping-text": {
    fontFamily: "Inter",
    color: "#0F172A",
    fontSize: "12px",
    fontWeight: 400,
  },
  "& .percent-text": {
    color: "#64748B",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  "& .overlapping-box-container": {
    marginBottom: 25
  },
  "& .overlapping-Label-percentage": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  "& .overlapping-message":{
   height:"200px",
   overflow:"scroll",
   
  },
  "& .buttons-main-box": {
    display: "flex",
    flexWrap: "wrap",
    gap: 20,
    marginTop: 24,
  },
  "& .buttons-box": {
    gap: "10px",
    cursor: "pointer",
    background: "#F5F5F4",
    borderRadius: "8px",
    border: "1px solid #AEB0B1",
    padding: "10px 16px",
    alignItems: "center",
    display: "flex",
  },
  "& .link-text": {
    textDecoration: "none"
  },
  "& .buttons-box-red": {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    borderRadius: "8px",
    gap: "10px",
    padding: "10px 16px",
    background: "#DF4833",
  },
  "& .button-text": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#4F5356",
    fontFamily: "Inter",
  },
  "& .button-text-white": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#FFFFFF",
    fontFamily: "Inter",
  },
  "& .report-buttons-box": {
    padding: "10px 0px 10px 16px",
    cursor: "pointer",
    background: "#FF9800",
    gap: "10px",
    width: "200px",
    alignItems: "center",
    borderRadius: "8px",
    display: "flex",
  },
  "& .report-button-text": {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
  }, "& .keyFeature-description-text": {
    fontFamily: "Inter",
    color: "#0F172A",
    marginTop: 16,
    fontWeight: 400,
    fontSize: 15,
  },
  "& .keyFeature-description-text-gridview": {
    fontFamily: "Inter",
    color: "#0F172A",
    marginTop: 16,
    fontWeight: 300,
    fontSize: 16,
  },
  "& .report-buttons-box.disabled": {
    pointerEvents: "none",
    cursor: "not-allowed",
    opacity: 0.5,
  },
  "& .report-buttons-box.enabled": {
    pointerEvents: "auto",
    cursor: "pointer",
    opacity: 1,
  },
  "& .placeholder-graph": {
    width: "100%",
    height: "284px",
    background: "rgba(245, 245, 244, 0.7)",
    backdropFilter: "blur(10px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    position: "relative",
  },
  "& .placeholder-overlay": {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#0F172A",
    fontSize: "18px",
    fontWeight: "bold",
    background: "rgba(255, 255, 255, 0.3)",
    borderRadius: "8px",
    cursor: "pointer",
  },
});

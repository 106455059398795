import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import {
  getStorageData,
} from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  downloadModel: boolean;
  patentId: string;
  loading: boolean;
  detailsData: any;
  selectedPublicationNumbers:string[];
  clearTrigger:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PublicationShowMappingController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiShowMappingDetailsAPIEndPoint: string = "";
  apiGetPublicationDownloadreportMappingCallId:string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),getName(MessageEnum.NavigationPayLoadMessage),];

    this.state = {
      selectedPublicationNumbers:[],
      clearTrigger:false,
      downloadModel: false,
      patentId: "",
      loading: true,
      detailsData: { mapping: [] }, 
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiShowMappingDetailsAPIEndPoint) {
      if (!responseJson.error) {
        const formattedDatak = this.transformData(responseJson);
        this.setState({ detailsData: formattedDatak,loading:false })
      }
      else {
        this.setState({ 
          loading: false 
        })
      }

    }
    else if (apiRequestCallId == this.apiGetPublicationDownloadreportMappingCallId) {
      if (responseJson) {
        this.setState({
          clearTrigger: true
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const publicationId = await getStorageData("scholar_id");
    this.setState({ patentId:publicationId ,selectedPublicationNumbers:publicationId});
    this.getPublicationDetailsAPIINdividual();      
   }

    transformData = (data: any) => {
    const mappings:any =  [];
    let aggregateOverlapPercentage = 0;

    const processKeyFeature = (startIndex: number) => {
        const keyFeatureLine = data.mapping[startIndex];
        const keyFeature = keyFeatureLine.split(": ")[1].trim();
        const keywordsLine = data.mapping[startIndex + 1];
        const overlappingLine = data.mapping[startIndex + 2];
        const snippetLine = data.mapping[startIndex + 3];
        const classificationLine = data.mapping[startIndex + 4];

        const overlappingPercentage = parseInt(overlappingLine.split(": ")[1]) || 0;
        const matchingText = {
            fromSnippet: snippetLine?.split(": ")[1]?.trim() || "N/A",
            fromClassification: classificationLine?.split(": ")[1]?.trim() || "N/A",
        };

        mappings.push({
            keyFeature,
            keywords: keywordsLine.split(": ")[1].split(",").map((keyword: string) => keyword.trim()),
            overlappingPercentage,
            matchingText
        });
    };

      let index = 0;
      while (index < data.mapping.length) {
        const line = data.mapping[index];

        if (line.startsWith("Key Feature:")) {
          processKeyFeature(index);
          index += 4; // Move past the processed lines
        } else if (line.startsWith("Total Aggregate Overlap Percentage:")|| line.startsWith("Aggregate Overlap Percentage:")) {
          aggregateOverlapPercentage = parseInt(line.split(": ")[1]) || 0;
        }

        index++; // Increment the index
      }

    return {
        mapping: mappings,
        aggregateOverlapPercentage
    };
};


  getPublicationDetailsAPIINdividual = async () => {
    const header = {
      token: await getStorageData("token"),
    };
    this.setState({ loading: true });
    const publicationId=this.state.patentId
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiShowMappingDetailsAPIEndPoint = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShowMappingDetailsAPIEndPoint}?scholar_id=${publicationId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPublicationDownloadreport = async (selectedPublicationNumbers: any) => {
    const Body = { 
      "scholar_ids": [selectedPublicationNumbers],      
      "case_id": await getStorageData("caseid")    
    };

    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPublicationDownloadreportMappingCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/publication_download_report`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
       JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
          'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  downloadDetails = () => {
    this.downloadOpenModel()
    this.getPublicationDownloadreport(this.state.selectedPublicationNumbers)
  }

  downloadCloseModel = () => {
    this.setState({ downloadModel: false });
  };

  downloadOpenModel = () => {
    this.setState({ downloadModel: true });
  };
  // Customizable Area End
}

import React from 'react';
// Customizable Area Start

import { Box, Button, TextField, IconButton } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';  
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check'; 
import TitleIcon from '@mui/icons-material/Title';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ProvisionalSpecificationPageController,{Props} from "./ProvisionalSpecificationPageController.web";
import Loader from "./Loader.web";
import { styled } from '@material-ui/core';

// Customizable Area End


// Customizable Area Start
// Define a section's structure
export interface Section {
  title: string;
  subtitle: string;
  index: number;
  content: string;
  prompt:string;
}

// Custom toolbar configuration
const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'link','image'],
  ],
};



// Customizable Area End
export default class ProvisionalSpecificationPage extends ProvisionalSpecificationPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.handleRedo = this.handleRedo.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <PageCss>
            <Loader loading={this.state.loading} />
              {!this.state.errors ? (
                <Box key={'Allsection'} sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  paddingLeft:"0px",
                }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px',gap:"5px",fontFamily: "Inter", }}>
                      <Button
                        id="view-specification-button"
                        data-testid="view-specification-button"
                        sx={{
                          backgroundColor: '#f44336',
                          color: '#fff',
                          textTransform: 'none',
                          fontFamily: "Inter",
                          '&:hover': {
                            backgroundColor: '#f44336',
                          },
                        }}
                        startIcon={<CalendarMonthIcon />}
                        onClick={() => this.goingToSpecificationPageReport()}
                      >
                        View Specification
                      </Button>
  
                    <Button
                        sx={{
                          backgroundColor: '#f44336',
                          color: '#fff',
                          textTransform: 'none',
                          '&:hover': {
                            backgroundColor: '#f44336',
                          },
                        }}
                        className='Save'
                        data-testid={`save-button`}
                        id={`save-button`}
                      onClick={() => this.handelSave()}
                      startIcon={<CheckIcon />}
                    >
                      Save
                    </Button>
                  </Box>
  
                  <Box>
                    {this.state.sections.map((section: Section, index: number) => (
                      <div 
                        key={`section-${section.index}`}
                        className={`section-quill-${section.index}`}
                        onMouseUp={this.handleMouseUp}
                        style={{
                          position: 'sticky',
                          backgroundColor: '#F5F5F4',
                          fontFamily: "Inter",
                          top: `${(section.index) * 69}px`,
                          border: '1px solid #ddd',
                          padding: '24px',
                          paddingTop: '12px',
                          borderRadius: '10px',
                          marginBottom: '32px'
                        }}
                        >
                        <Box>
                          <h3 id={`section-title-${section.index}`} style={{ fontWeight: 'bold', margin: '8px',    fontFamily: "Inter", }}>{section.title}</h3>
                          <h5 id={`section-subtitle-${section.index}`} style={{ fontWeight: 'normal', color: '#474747', margin: '8px',    fontFamily: "Inter", }}>{section.subtitle}</h5>
                          <Box sx={{ position: 'relative', marginBottom: '16px' }}>
                            <ReactQuill
                              theme="snow"
                              value={section.content}
                              onChange={(e) => this.handleContentChange(e, index)}
                              modules={modules}
                              style={{ backgroundColor: '#fff', height: '250px', borderRadius: '10px', paddingBottom:'10px',   fontFamily: "Inter",}}
                              data-testid={`quill-editor-${section.index}`}
                              id={`quill-editor-${section.index}`}
                            />
                            
                            <Box sx={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', gap: '5px',    fontFamily: "Inter", }}>
                              <IconButton id={`undo-button-${section.index}`} aria-label="undo" onClick={() => this.handleUndo(index)}
                                  sx={{
                                    width: '36px',
                                    height: '36px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '1px solid #ddd',
                                    borderRadius: '5px',
                                    backgroundColor: '#fff',
                                    transition: 'background-color 0.2s ease, border-color 0.2s ease'
                                  }}
                                >
                                <UndoIcon />
                              </IconButton>
                              <IconButton id={`redo-button-${section.index}`} aria-label="redo" onClick={() => this.handleRedo(index)}
                                  sx={{
                                    width: '36px',
                                    height: '36px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '1px solid #ddd',
                                    borderRadius: '5px',
                                    backgroundColor: '#fff'
                                  }}
                                >
                                <RedoIcon />
                              </IconButton>
                            </Box>
                          </Box>  
                          <Box sx={{marginTop: "80px"}}>

                          
                          <CustomTextField
className={`prompt-${index}`}
placeholder="Summary of the invention"
  fullWidth
  variant="outlined"
  id={`prompt-${section.index}`}
  data-testid={`prompt-${section.index}`}
  value={section.prompt}
  onChange={(e) => this.handlePromptChange(e, index)}
  multiline
  InputProps={{
    startAdornment: (
      <SearchIcon
       className='leftSearchIcon'
      />
    ),
    endAdornment: (
      <IconButton
        className={`promptcall-${index}`}
        id={`promptcall-${section.index}`}
        data-testid={`promptcall-${section.index}`}
        onClick={() => this.promptApiCall(index)}
        disabled={this.state.disableprompt}
        sx={{
          right: '10px', 
          position: 'absolute',
          top: '10px',
        }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    ),
  }}
  sx={{
    '& .MuiInputBase-input': {
      marginTop: '10px', 
      maxHeight: '120px', 
      overflow: 'auto',   
    },
    '& .MuiOutlinedInput-root': {
      alignItems: 'flex-start',
      paddingLeft: '40px',
      paddingRight: '40px', 
      minHeight: '120px',
    },
  
  }}
/>

                          </Box>
                          <Box sx={{ display: 'flex', gap: '8px',marginTop:"10px",  borderRadius: '8px',   fontFamily: "Inter", }}>
                            <Button
                              onClick={() => this.Replace(index)}
                              id={`replace-${section.index}`}
                              data-testid={`replace-${section.index}`}
                              className='replaceText'>
                              <TitleIcon /> Replace
                            </Button>
                            <Button
                              disabled={this.state.disablerewrite}
                              onClick={() => this.Rewrite(index)}
                              id={`writeAI-${section.index}`}
                              data-testid={`writeAI-${section.index}`}
                             className='rewriteText'>
                              <TitleIcon /> Rewrite
                            </Button>
                          </Box>
                        </Box>
                      </div>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box component="div" className="no-result-text">No Results Found</Box>
              )}         
      </PageCss>
    );
  }
  
}

// Customizable Area Start

const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '66px',
  overflow: 'auto',
  backgroundColor: '#fff',
  fontFamily: 'Inter',
  '& .MuiInputBase-input': {
    color: '#000', 
  },
  '& .MuiIconButton-root': {
    color: '#f44336',
  },
  '& .MuiInputBase-root': {
    color: '#6c757d',
    paddingTop:'0px',
  },
}));

const PageCss = styled(Box)({
    "& .classification-white-container": {

      background: "#E8E7E7",
      fontFamily: "Inter",
      "@media(max-width:1050px)": {
        padding: "40px 40px",
      },
      "@media(max-width:920px)": {
        padding: "40px 20px",
      },
    },
    "& .back-arrow-box": {
      gap: 16,
      display: "flex",
      alignItems: "center",
      marginBottom: "24px",
      fontFamily: "Inter",
    },
    "& .back-arrow-img": {
      cursor: "pointer"
    },
    "& .back-arrow-text": {
      fontSize: "18px",
      fontFamily: "Inter",
      color: "#212324",
      fontWeight: 400,
    },
    "& .no-result-text": {
    fontWeight: 700,
    fontSize: 24,
    fontFamily: "Inter",
    justifyContent: "center",
    height: "200px",
    display: "flex",
    alignItems: "center"
  },
  "& .leftSearchIcon":{
    position: "absolute",
    left: "10px",
    top: "22px",
    transform: "translateY(-50%)",
    color: "black",
  },
  "& .replaceText":{
    textTransform: 'none',
    borderRadius: '8.5px',
    paddingLeft: '10px',
    gap: "2px",
    border: '1px solid #ddd',
    color: '#474747',
    fontFamily: "Inter",
  },
  "& .rewriteText":{
    color: '#474747',
    gap: "2px",
    fontFamily: "Inter",
    textTransform: 'none',
    borderRadius: '8.5px',
    paddingLeft: '10px',
    border: '1px solid #ddd',
  },
  "& .ql-toolbar": {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    backgroundColor: "#fff", 
    marginBottom: "10px", 
    padding: "10px",
    border: "none", 
    borderBottom: "1.8px solid black", 
  },
  "& .ql-editor img" :{
    width: "100px",
    height: "100px", 
    borderRadius: "2px",
  },
  
  "& .ql-toolbar .ql-formats": {
    gap: "5px", 
    display: "flex",
  },
  "& .ql-toolbar button:hover": {
    backgroundColor: "#e0e0e0", 
  },
  "& .ql-toolbar button, & .ql-toolbar .ql-picker": {
    width: "36px",
    border: "1px solid #ddd",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px", 
    backgroundColor: "#fff",
    cursor: "pointer",
    transition: "background-color 0.2s ease, border-color 0.2s ease",
  },
  "& .ql-toolbar .ql-picker-label": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 5px",
    height: "100%",
  },
  "& .ql-toolbar .ql-picker-options": {
    borderRadius: "5px",
    border: "1px solid #ddd",
    backgroundColor: "#fff",
    zIndex: 100,
  },
  "& .ql-toolbar .ql-picker": {
    width: "auto", 
  },
  "& .ql-toolbar button.ql-active": {
    borderColor: "#bbb",
    backgroundColor: "#d4d4d4",
  },
  "& .ql-container": {
    border: "none", 
  },
   "& .editor-wrapper": {
    margin: "10px", 
    background: "#fff", 
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", 
    borderRadius: "8px",     
  },
  "& .ql-editor": {
    border: "1px solid #ddd", 
    padding: "15px",
    borderRadius: "8px", 
    
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "1.5",
    backgroundColor: "#fff", 
    marginTop: "10px", 
  },
  "& .ql-tooltip": {
    backgroundColor: "#fff",
    border: "1px solid #ddd", 
    borderRadius: "5px", 
    
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", 
    fontFamily: "Inter",
    padding: "10px",
  },
  
  });

// Customizable Area End

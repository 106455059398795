import React from 'react';
// Customizable Area Start

import { Box, Button, TextField, IconButton } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/Check';
import TitleIcon from '@mui/icons-material/Title';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@material-ui/core';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NonProvisionalSpecificationPageController, { Props } from "./NonProvisionalSpecificationPageController.web";
import Loader from "./Loader.web";

// Customizable Area End


// Customizable Area Start
// Define a section's structure
export interface Section {
  title: string;
  subtitle: string;
  content: string;
  index: number;
  prompt: string;
}

// Custom toolbar configuration
const modules = {
  toolbar: [
    ['bold',
      'italic',
      'underline',
      'link','image'],
  ],
};



// Customizable Area End
export default class ProvisionalSpecificationPage extends NonProvisionalSpecificationPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleUndo = this.handleUndo.bind(this);
    this.handleMouse = this.handleMouse.bind(this);
    this.handleRedo = this.handleRedo.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <Page>
            <Loader loading={this.state.loading} />
              {this.state.errors ? (
                <Box component="div" className="no-result-text">No Results Found</Box>
              ) : (
                <Box key={'Allsection'} sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  paddingLeft:"0px",
                }}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: '16px',
                    gap: "5px",
                    fontFamily: "Inter",
                  }}>
                          <Button
                            startIcon={<CalendarMonthIcon />}
                            onClick={() => this.goingToSpecificationPage()}
                            id="view-specification-button"
                            sx={{
                              backgroundColor: '#f44336',
                              fontFamily: "Inter",
                              '&:hover': {
                                backgroundColor: '#f44336',
                              },
                              color: '#fff',
                              textTransform: 'none',
                            }}
                            data-testid="view-specification-button"
                          >
                        View Specification
                      </Button>

                    <Button
                     data-testid={`save-button`}
                     id={`save-button`}
                      sx={{
                        backgroundColor: '#f44336',                      
                        '&:hover': {
                          backgroundColor: '#f44336',
                        },
                        color: '#fff',
                        textTransform: 'none',
                      }}
                      className='Save'    
                      startIcon={<CheckIcon />}                 
                      onClick={() => this.handelSave()}
                    >
                      Save
                    </Button>
                  </Box>

                  <Box>
                    {this.state.sections.map((section: Section, index: number) => (
                      <div
                        className={`section-quill-${section.index}`}
                        style={{
                          top: `${(section.index) * 69}px`,
                          position: 'sticky',
                          backgroundColor: '#F5F5F4',
                          fontFamily: "Inter",
                          border: '1px solid #ddd',
                          padding: '24px',
                          paddingTop: '12px',
                          borderRadius: '10px',
                          marginBottom: '32px'
                        }}
                          onMouseUp={this.handleMouse}
                          key={`section-${section.index}`}
                      >
                        <Box>
                          <h3
                            style={{
                              fontWeight: 'bold',
                              margin: '8px',
                              fontFamily: "Inter",
                            }}
                            id={`section-title-${section.index}`}
                          >
                            {section.title}
                          </h3>
                          <h5
                            id={`section-subtitle-${section.index}`}
                            style={{
                              fontWeight: 'normal',
                              color: '#474747',
                              margin: '8px',
                              fontFamily: "Inter",
                            }}>
                            {section.subtitle}</h5>
                          <Box sx={{ position: 'relative', marginBottom: '16px' }}>
                            <ReactQuill
                              data-testid={`quill-editor-${section.index}`}
                              id={`quill-editor-${section.index}`}
                              theme="snow"
                              value={section.content}
                              modules={modules}
                              style={{
                                paddingBottom:'10px',
                                backgroundColor: '#fff',
                                height: '250px',
                                borderRadius: '10px',
                                fontFamily: "Inter",
                              }}
                              onChange={(e) => this.handleContentChange(e, index)}

                            />

                            <Box
                              sx={{
                                right: '10px',
                                position: 'absolute',
                                top: '11px',
                                fontFamily: "Inter",
                                display: 'flex',
                                gap: '5px',
                              }}>
                              <IconButton
                                id={`undo-button-${section.index}`}
                                aria-label="undo"
                                onClick={() => this.handleUndo(index)}
                                sx={{
                                  width: '36px',
                                  height: '36px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  border: '1px solid #ddd',
                                  borderRadius: '5px',
                                  backgroundColor: '#fff',
                                  transition: 'background-color 0.2s ease, border-color 0.2s ease'
                                }}
                              >
                                <UndoIcon />
                              </IconButton>
                              <IconButton
                                id={`redo-button-${section.index}`}
                                aria-label="redo"
                                onClick={() => this.handleRedo(index)}
                                sx={{
                                  width: '36px',
                                  height: '36px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  border: '1px solid #ddd',
                                  borderRadius: '5px',
                                  backgroundColor: '#fff',
                                  transition: 'background-color 0.2s ease, border-color 0.2s ease'
                                }}
                              >
                                <RedoIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          <Box sx={{marginTop: "80px"}}>
                          <CustomTextField
                            placeholder="Summary of the invention"
                            fullWidth
                            className={`prompt-${index}`}
                            onChange={(e) => this.handlePrompt(e, index)}
                            variant="outlined"
                            data-testid={`prompt-${section.index}`}
                            id={`prompt-${section.index}`}
                            value={section.prompt}            
                            multiline
                            InputProps={{
                              startAdornment:   <SearchIcon
                              className='leftSearchIcon'
                              />,
                              endAdornment: (
                                <IconButton
                                  onClick={() => this.promptApiCall(index)}
                                  data-testid={`promptcall-${section.index}`}
                                  className={`promptcall-${index}`}
                                  id={`promptcall-${section.index}`}
                                  disabled={this.state.disableprompt}
                                  sx={{
                                    position: 'absolute',
                                    right: '10px', 
                                    top: '10px',
                                  }}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              ),
                            }}
                            sx={{
                              '& .MuiInputBase-input': {
                                marginTop: '10px', 
                                maxHeight: '120px', 
                                overflow: 'auto',   
                              },
                              '& .MuiOutlinedInput-root': {
                                alignItems: 'flex-start',
                                paddingLeft: '40px',
                                paddingRight: '40px', 
                                minHeight: '120px',
                              },
                            }}
                          />
                 </Box>
                 <Box sx={{ display: 'flex',marginTop:"10px", gap: '8px',  borderRadius: '8px',   fontFamily: "Inter", }}>
                            <Button
                              data-testid={`replace-${section.index}`}
                              onClick={() => this.ReplaceApiCall(index)}
                              id={`replace-${section.index}`}
                              className='replaceText'                          
                            >
                              <TitleIcon /> Replace
                            </Button>
                            <Button
                              onClick={() => this.RewriteApiCall(index)}
                              data-testid={`writeAI-${section.index}`}
                              id={`writeAI-${section.index}`}
                              disabled={this.state.disablerewrite}
                              className='rewriteText'>
                              <TitleIcon /> Rewrite
                            </Button>
                          </Box>
                        </Box>
                      </div>
                    ))}
                  </Box>
                </Box>
              )}
      </Page>
    );
  }

}

// Customizable Area Start
const CustomTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '66px',
  overflow: 'auto',
  backgroundColor: '#fff',
  fontFamily: 'Inter',
  '& .MuiInputBase-input': {
    color: '#000', 
  },
  '& .MuiIconButton-root': {
    color: '#f44336',
  },
  '& .MuiInputBase-root': {
    color: '#6c757d',
    paddingTop:'0px',
  },
}));

const Page = styled(Box)({
  "& .classification-white-container": {
    "@media(max-width:1050px)": {
      padding: "40px 40px",
    },
    "@media(max-width:920px)": {
      padding: "40px 20px",
    },
    padding: "50px 108px",
    background: "#E8E7E7",
    fontFamily: "Inter",
  },
  "& .back-arrow-box": {
    fontFamily: "Inter",
    gap: 16,
    display: "flex",
    alignItems: "center",
    marginBottom: "24px",
  },
  "& .no-result-text": {
    height: "200px",
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 24,
    fontFamily: "Inter",
    justifyContent: "center",
  },
  "& .leftSearchIcon": {
    position: "absolute",
    left: "10px",
    top: "22px",
    transform: "translateY(-50%)",
    color: "black",
  },
  "& .replaceText": {
    textTransform: "none",
    borderRadius: "8.5px",
    paddingLeft: "10px",
    gap: "2px",
    border: "1px solid #ddd",
    color: "#474747",
    fontFamily: "Inter",
  },
  "& .rewriteText": {
    color: "#474747",
    gap: "2px",
    fontFamily: "Inter",
    textTransform: "none",
    borderRadius: "8.5px",
    paddingLeft: "10px",
    border: "1px solid #ddd",
  },
  "& .back-arrow-img": {
    cursor: "pointer",
  },
  "& .back-arrow-text": {
    fontFamily: "Inter",
    color: "#212324",
    fontSize: "18px",
    fontWeight: 400,
  },

  "& .ql-toolbar": {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    backgroundColor: "#fff", 
    padding: "10px",
    border: "none", 
    borderBottom: "1.8px solid black", 
    marginBottom: "10px", 
  },
  "& .ql-editor img" :{
    height: "100px",
    width: "100px", 
    borderRadius: "2px",
  },
  "& .ql-toolbar .ql-formats": {
    display: "flex",
    gap: "5px",
  },
  "& .ql-toolbar button, & .ql-toolbar .ql-picker": {
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #ddd",
    borderRadius: "5px", 
    backgroundColor: "#fff",
    cursor: "pointer",
    transition: "background-color 0.2s ease, border-color 0.2s ease",
  },
  "& .ql-toolbar button:hover": {
    backgroundColor: "#e0e0e0",
  },
  "& .ql-toolbar button.ql-active": {
    backgroundColor: "#d4d4d4",
    borderColor: "#bbb",
  },
  "& .ql-toolbar .ql-picker": {
    width: "auto",
  },
  "& .ql-toolbar .ql-picker-label": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 5px",
    height: "100%",
  },
  "& .ql-toolbar .ql-picker-options": {
    border: "1px solid #ddd",
    borderRadius: "5px",
    backgroundColor: "#fff",
    zIndex: 100,
  },

  "& .editor-wrapper": {
    margin: "10px", 
    background: "#fff", 
    borderRadius: "8px", 
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", 
  },
  "& .ql-container": {
    border: "none", 
  },

  "& .ql-editor": {
    border: "1px solid #ddd", 
    borderRadius: "8px", 
    padding: "15px",
    fontFamily: "Inter",
    fontSize: "16px",
    lineHeight: "1.5",
    backgroundColor: "#fff", 
    marginTop: "10px", 
  },
  "& .ql-tooltip": {
    border: "1px solid #ddd", 
    borderRadius: "5px", 
    backgroundColor: "#fff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", 
    fontFamily: "Inter",
    padding: "10px",
  },
});

// Customizable Area End
